import { useState } from 'react'
import useSnackbarAlert from 'hooks/useSnackbar'
import { snackbarProps } from 'interfaces/snackbar'
import {
  generateUploadVideoLinksService,
  uploadImageService,
  uploadFileService,
  uploadAudioService,
  exportDataService,
  extractTextService,
  uploadCaptionsService,
} from 'services/helperService/helperService'
import { useTranslation } from 'react-i18next'
import { CropperResult } from 'interfaces/common'
import { FormikErrors } from 'formik'
import { formDataInterface } from 'pages/tests/addTestDrawer/addTestDrawer.interface'

export const useGenerateUploadVideoLinks = () => {
  const { t } = useTranslation()
  const { generateUploadVideoLinksMutation } = generateUploadVideoLinksService()
  const { setSnackbar } = useSnackbarAlert()

  const generateUploadVideoLinks = async (
    videos: any,
    modelName: string,
    uploadVideoService: any,
    setVideoLinks: (args: any) => void,
    isNewVideo?: boolean,
  ): Promise<void> => {
    let { message, variant }: snackbarProps = {
      message: '',
      variant: undefined,
    }

    const { data, errors } = await generateUploadVideoLinksMutation({
      variables: {
        videos,
        modelName,
        isNewVideo,
      },
    })

    if (errors && errors.length) {
      message = t('messages.something_wrong')
      variant = 'error'

      setSnackbar({ message, variant })
    } else {
      const promises = data.generateVideoUploadLinks.uploadLinks.map(
        (url: string) => uploadVideoService(url),
      )

      await Promise.all(promises)

      setVideoLinks(data.generateVideoUploadLinks)
    }
  }

  return {
    generateUploadVideoLinks,
  }
}

export const useUploadImage = () => {
  const { uploadImageMutation } = uploadImageService()
  const [imageLoading, setImageLoading] = useState('')
  const [file, setFile] = useState<CropperResult>('')
  const [cropperOpened, setCropperOpened] = useState(false)

  const uploadImage = async (
    image: File | string,
    path: string,
    setImageLink: any,
  ) => {
    if (!image) return

    let base64Image: string
    if (typeof image === 'string') {
      base64Image = image
    } else {
      base64Image = await new Promise<string>((resolve, reject) => {
        const reader = new FileReader()
        reader.readAsDataURL(image)
        reader.onload = (): void => resolve(reader.result as string)
        reader.onerror = (error): void => reject(error)
      })
    }

    const loadingType = path.split('/')[1]
    setImageLoading(loadingType || 'avatar')
    const { data, errors } = await uploadImageMutation({
      variables: { image: base64Image, path },
    })

    if (errors && errors.length) {
      return
    }
    setImageLoading('')
    setImageLink(data.uploadImageLink.link)
  }

  const handleImageChange = (e: any) => {
    const { files, name } = e.target
    const reader = new FileReader()

    reader.onload = () => {
      const img = new Image()
      img.src = reader.result as string
      setFile({ name, file: reader.result as string })
    }
    reader.readAsDataURL(files[0])
    setCropperOpened(true)
  }

  const handleCropSave = (
    croppedFile: File,
    collection: string,
    field: string,
    setFieldValue: (
      field: string,
      value: any,
    ) => Promise<void> | Promise<FormikErrors<formDataInterface>>,
  ) => {
    uploadImage(croppedFile, `${collection}/${field}`, (link: string) =>
      setFieldValue(field, {
        name: Date.now().toString(),
        link,
        fileType: 'png',
      }),
    )
  }

  return {
    uploadImage,
    imageLoading,
    handleCropSave,
    handleImageChange,
    file,
    cropperOpened,
    setCropperOpened,
  }
}

export const useUploadFile = () => {
  const { uploadFileMutation } = uploadFileService()
  const [fileLoading, setFileLoading] = useState('')

  const uploadFile = async (file: File, path: string, setFileLink: any) => {
    const loadingType = path.split('/')[1]
    setFileLoading(loadingType)
    const { data, errors } = await uploadFileMutation({
      variables: { file, path },
    })

    if (errors && errors.length) {
      return
    }
    setFileLoading('')
    setFileLink(data.uploadFileLink.link, data.uploadFileLink.fileId)
  }

  return {
    uploadFile,
    fileLoading,
  }
}

export const useUploadCaptions = () => {
  const { uploadFileMutation } = uploadCaptionsService()
  const [captionUploadLoading, setCaptionUploadLoading] = useState('')

  const uploadCaptions = async (file: File, path: string, setFileLink: any) => {
    const loadingType = path.split('/')[1]
    setCaptionUploadLoading(loadingType)
    const { data, errors } = await uploadFileMutation({
      variables: { file, path },
    })

    if (errors && errors.length) {
      return
    }
    setCaptionUploadLoading('')
    setFileLink(data.uploadCaptions.link, data.uploadCaptions.fileId)
  }

  return {
    uploadCaptions,
    captionUploadLoading,
  }
}

export const useUploadAudioService = () => {
  const { uploadAudioMutation, loading } = uploadAudioService()

  const uploadAudio = async (values: any, uploadApi: any, call: any) => {
    const { data, errors } = await uploadAudioMutation({
      variables: {
        file: values,
      },
    })

    if (errors && errors.length) {
      return
    }

    const promises = [uploadApi(data.uploadAudio.link)]

    await Promise.all(promises)

    call(data.uploadAudio.fileId)
  }

  return {
    uploadAudio,
    audioLoading: loading,
  }
}

export const useExportDataService = () => {
  const { exportDataMutation, loading } = exportDataService()

  const exportData = async (action: string, ids: string[], callback: any) => {
    const { data, errors } = await exportDataMutation({
      variables: {
        action,
        ids,
      },
    })

    if (errors && errors.length) {
      return
    }

    callback(data && data.exportCsv.link)
  }

  return {
    loading,
    exportData,
  }
}

export const useExtractTextService = () => {
  const { extractTextMutation, loading } = extractTextService()
  const [fileLoading, setFileLoading] = useState('')

  const extractText = async (link: string, callback: any, field = '') => {
    setFileLoading(field)
    const { data, errors } = await extractTextMutation({
      variables: {
        link,
      },
    })

    if (errors && errors.length) {
      return
    }
    setFileLoading('')
    callback(data && data.extractText.text)
  }

  return {
    loading: fileLoading,
    extractText,
  }
}
export const useFormatTaskTrigger = () => {
  const formatTriggerValue = (value: string): string => {
    switch (value) {
      case 'BEFORE_MODULE':
      case 'BEFORE_COURSE':
        return 'Before Start'
      case 'AFTER_MODULE':
      case 'AFTER_COURSE':
        return 'After Finish'
      default:
        return value
          .toLowerCase()
          .split('_')
          .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
          .join(' ')
    }
  }
  return { formatTriggerValue }
}

export function excludeKeys<T, K extends keyof T>(
  obj: T,
  keys: K[],
): Omit<T, K> {
  const result = { ...obj }
  for (const key of keys) {
    delete result[key]
  }
  return result
}
