import React, { useEffect } from 'react'
import {
  FormRules,
  FormRulesItem,
  FormGroupWrapper,
  FormGroup,
  FormGroupItem,
  QuestionContainer,
  StyledTextareaAutosize,
} from './styled-components'
import { ReactComponent as AddCircleIcon } from 'assets/dark-add-alt.svg'
import { ReactComponent as RemoveCircleIcon } from 'assets/remove-alt.svg'
import { ReactComponent as RemoveCircleIconDisable } from 'assets/remove-alt-disable.svg'
import Radio from '@mui/material/Radio'
import Checkbox from 'components/common/Checkbox'
import RadioGroup from '@mui/material/RadioGroup'
import FormControlLabel from '@mui/material/FormControlLabel'
import { useTranslation } from 'react-i18next'
import { QuestionTypeEnums } from './CreateQuestion.interface'
import { Title } from 'components/DrawerLayout/styled-components'
import Tooltip from '@mui/material/Tooltip'
import { TFunction } from 'interfaces/TFunction'

interface Params {
  type: string
  onBoolChange?: any
  handleAddAnswerClick?: any
  boolAnswer: boolean
  choiceAnswers?: any
  multiAnswers?: any
  handleRemoveAnswerClick?: any
  handleAnswerChange?: any
  selectedChoiceAnswer?: any
  selectedMultiAnswers?: any
  handleSelect?: any
  fieldsValid: boolean | undefined
  selectedValid: boolean | undefined
}

const renderMulti = (
  t: TFunction,
  handleAddAnswerClick: any,
  handleRemoveAnswerClick: any,
  multiAnswers: any,
  handleAnswerChange: any,
  handleSelect: any,
  selectedMultiAnswers: any,
  fieldsValid?: boolean | undefined,
  selectedValid?: boolean | undefined,
) => {
  if (multiAnswers.length < 2) {
    multiAnswers = [...multiAnswers, { value: '' }, { value: '' }]
  }

  return (
    <>
      <Title className="full">
        {t('create_question.add_and_select_correct_answer')}
      </Title>
      <FormRules>
        <FormRulesItem className={fieldsValid ? 'valid' : ''}>
          {t('create_question.please_add_and_fill_all_answers')}
        </FormRulesItem>
        <FormRulesItem className={selectedValid ? 'valid' : ''}>
          {t(
            'create_question.please_add_and_select_more_than_one_correct_answer',
          )}
        </FormRulesItem>
      </FormRules>
      <FormGroupWrapper>
        <FormGroup>
          {multiAnswers.map((multiAnswer: any, index: number) => (
            <FormGroupItem key={index}>
              <Checkbox
                name={`multiple-${index}`}
                value={index}
                checked={selectedMultiAnswers.includes(index)}
                onChange={(e: React.SyntheticEvent<EventTarget>) =>
                  handleSelect(index, 'multiple')
                }
              />
              <StyledTextareaAutosize
                name={`multiple-${index}`}
                aria-label="minimum height"
                minRows={2}
                value={multiAnswer.value}
                placeholder={t('general.type_answer')}
                onChange={(e) => handleAnswerChange(e, 'multiple', index)}
              />

              {multiAnswers.length < 3 ? (
                <Tooltip
                  title={
                    <span style={{ fontSize: '14px' }}>
                      {t('questions_layout.disabled_option')}
                    </span>
                  }
                >
                  <RemoveCircleIconDisable />
                </Tooltip>
              ) : (
                <RemoveCircleIcon
                  onClick={() => handleRemoveAnswerClick('multiple', index)}
                  style={{ color: '#06C68F' }}
                />
              )}
            </FormGroupItem>
          ))}
        </FormGroup>
      </FormGroupWrapper>
      <div
        style={{
          display: 'flex',
          justifyContent: 'flex-end',
          marginTop: '10px',
        }}
      >
        <span style={{ marginRight: '8px', color: '#08C694' }}>
          {t('general.add_answer')}
        </span>{' '}
        <AddCircleIcon
          onClick={() => handleAddAnswerClick('multiple')}
          style={{ color: '#06C68F' }}
        />
      </div>
    </>
  )
}
const renderChoice = (
  t: TFunction,
  handleAddAnswerClick: any,
  choiceAnswers: any,
  handleRemoveAnswerClick: any,
  handleAnswerChange: any,
  selectedChoiceAnswer?: any,
  handleSelect?: any,
  fieldsValid?: boolean | undefined,
  selectedValid?: boolean | undefined,
) => {
  if (choiceAnswers.length < 2) {
    choiceAnswers = [...choiceAnswers, { value: '' }, { value: '' }]
  }

  return (
    <>
      <Title className="full">
        {t('create_question.add_and_select_correct_answer')}
      </Title>
      <FormRules>
        <FormRulesItem className={fieldsValid ? 'valid' : ''}>
          {t('create_question.please_add_and_fill_all_answers')}
        </FormRulesItem>
        <FormRulesItem className={selectedValid ? 'valid' : ''}>
          {t('create_question.please_select_one_correct_answer')}
        </FormRulesItem>
      </FormRules>
      <FormGroupWrapper>
        <FormGroup>
          {choiceAnswers.map((choiceAnswer: any, index: number) => (
            <FormGroupItem key={index}>
              <Radio
                name="single"
                value={index}
                checked={selectedChoiceAnswer == index}
                onChange={(e) => handleSelect(e, 'single')}
              />
              <StyledTextareaAutosize
                name={`single-${index}`}
                aria-label="minimum height"
                minRows={2}
                placeholder={t('general.type_answer')}
                value={choiceAnswer.value}
                onChange={(e: any) => handleAnswerChange(e, 'single', index)}
              />

              {choiceAnswers.length < 3 ? (
                <Tooltip
                  title={
                    <span style={{ fontSize: '14px' }}>
                      {t('questions_layout.disabled_option')}
                    </span>
                  }
                >
                  <RemoveCircleIconDisable />
                </Tooltip>
              ) : (
                <RemoveCircleIcon
                  onClick={() => handleRemoveAnswerClick('single', index)}
                  style={{ color: '#06C68F' }}
                />
              )}
            </FormGroupItem>
          ))}
        </FormGroup>
      </FormGroupWrapper>
      <div
        style={{
          display: 'flex',
          justifyContent: 'flex-end',
          marginTop: '10px',
        }}
      >
        <span style={{ marginRight: '8px', color: '#08C694' }}>
          {t('general.add_answer')}
        </span>{' '}
        <AddCircleIcon
          onClick={() => handleAddAnswerClick('single')}
          style={{ color: '#06C68F' }}
        />
      </div>
    </>
  )
}

const renderBool = (onBoolChange: any, boolAnswer: boolean, t: TFunction) => (
  <QuestionContainer>
    <Title>{t('create_question.tick_correct_answer')}:</Title>
    <RadioGroup
      row
      aria-label="position"
      name="position"
      defaultValue="top"
      onChange={onBoolChange}
      value={`${boolAnswer}`}
    >
      <FormControlLabel
        value="true"
        control={<Radio color="secondary" />}
        label={t('general.true')}
      />
      <FormControlLabel
        value="false"
        control={<Radio color="secondary" />}
        label={t('general.false')}
      />
    </RadioGroup>
  </QuestionContainer>
)

const QuestionItem = ({
  type,
  boolAnswer,
  choiceAnswers = [],
  multiAnswers = [],
  onBoolChange,
  handleAddAnswerClick,
  handleRemoveAnswerClick,
  handleAnswerChange,
  selectedChoiceAnswer,
  selectedMultiAnswers,
  handleSelect,
  fieldsValid,
  selectedValid,
}: Params) => {
  const { t } = useTranslation()
  useEffect(() => {
    const addAnswersIfNeeded = (
      typeEnum: string,
      answers: any[],
      answerType: string,
    ) => {
      if (type === typeEnum && answers.length < 2) {
        handleAddAnswerClick(answerType)
        handleAddAnswerClick(answerType)
      }
    }

    addAnswersIfNeeded(QuestionTypeEnums.SINGLE, choiceAnswers, 'single')
    addAnswersIfNeeded(QuestionTypeEnums.MULTIPLE, multiAnswers, 'multiple')
  }, [type, choiceAnswers, multiAnswers, handleAddAnswerClick])

  return (
    <>
      {type === QuestionTypeEnums.BOOLEAN &&
        renderBool(onBoolChange, boolAnswer, t)}
      {type === QuestionTypeEnums.SINGLE &&
        renderChoice(
          t,
          handleAddAnswerClick,
          choiceAnswers,
          handleRemoveAnswerClick,
          handleAnswerChange,
          selectedChoiceAnswer,
          handleSelect,
          fieldsValid,
          selectedValid,
        )}
      {type === QuestionTypeEnums.MULTIPLE &&
        renderMulti(
          t,
          handleAddAnswerClick,
          handleRemoveAnswerClick,
          multiAnswers,
          handleAnswerChange,
          handleSelect,
          selectedMultiAnswers,
          fieldsValid,
          selectedValid,
        )}
    </>
  )
}

export default QuestionItem
